span.with-sublabel {
  display: block;
  position: relative;

  > span {
    position: absolute;
    left: 0px;
    top: 16px;
    font-size: 12px;
    letter-spacing: 0.1px;
  }
}

small.self-assigned {
  display: inline-block;
  font-size: 14px;
  padding: 0.5em;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 6px;
  &:hover {
    cursor: pointer;
  }
  &.not-permission {
    background-color: initial;
    color: #6c6e7a !important;

    &:hover {
      cursor: default;
    }
  }
}

small.self-assigned,
span.self-assigned {
  background-color: $activeState;
  color: $gray-800;
  font-weight: bold;
}

.deactivated-user {
  color: $gray-200 !important;
  background: none;

  &.assignable {
    &:not(.not-permission):hover {
      cursor: pointer;
      color: $cyan !important;
    }
  }
  .fa-ban {
    color: $red-100;
  }
}

.ReactVirtualized__Table.trx-table {
  th,
  td {
    border-top: none;
  }

  .ReactVirtualized__Table__headerRow {
    .ReactVirtualized__Table__headerColumn {
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      color: $gray-400;
      border-bottom: none;
      padding: 0.75rem 0;
      &:first-child {
        width: 2rem;
      }
    }
  }

  .editable:hover {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
    .ReactVirtualized__Table__row {
      // min-height: 80px;
      border-bottom: solid 1px $gray-100;
      // cursor: pointer;
      &.cursor-default {
        cursor: default;
      }

      &:first-child {
        border-top: solid 1px $gray-100;
      }

      &.edit-row {
        background: $tableHover;
      }

      &.loading {
        td {
          background: $pearl;
        }
        span {
          display: inline-block;
          width: 80%;
          background: $gray-200;
          height: 12px;
          border-radius: 5px;
          opacity: 0.5;
        }
      }

      &:hover {
        background-color: $tableHover;
      }
    }

    .ReactVirtualized__Table__rowColumn {
      font-size: 14px;
      vertical-align: middle;
      padding: 0.75rem 0;

      .main-link {
        font-weight: bold;
        color: $gray-800;
        &:hover {
          color: $primary;
        }
        &.inactive {
          color: $gray-200;
        }
      }

      .secondary-link {
        color: $gray-800;
        &:hover {
          color: $primary;
        }
      }

      &.drag-icon,
      .drag-icon {
        width: 1rem;
        z-index: 2;
        svg {
          margin-right: 0.5rem;
          color: $gray-400;
          cursor: all-scroll;
          &:hover {
            color: $blue-400;
          }
        }

        &.disabled {
          svg {
            cursor: pointer;
          }
        }
      }
    }
  }

  .ReactVirtualized__Grid {
    .ReactVirtualized__Table__row.selected {
      background-color: $tableHover;
    }
  }
}

span.assignee:not(.not-assignee) {
  display: inline-block;
  font-size: 14px;
  padding: 0.5em;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 6px;
  cursor: pointer;
  background-color: $blue-100;
  color: $blue-300;
  svg.fa-chevron-down {
    display: none !important;
    color: $blue-300;
  }
  &:not(.not-permission):hover svg.fa-chevron-down {
    display: inline !important;
  }

  &.not-permission {
    color: $gray-800;
    cursor: default;
    &.not-assigned {
      color: #6c757d !important;
    }
  }

  &.self-assigned {
    font-weight: bold;
  }
  &.sub-domain {
    background-color: transparent;
    &.self-assigned {
      background-color: $blue-100;
    }
    &.not-assigned {
      color: $gray-800 !important;
    }
  }
  &.sub-assigned,
  &.deactivated-user {
    background-color: transparent;
    &.not-badge {
      padding: 0;
    }
  }
  &.not-highlight {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1500px) {
  .trx-table .ReactVirtualized__Table__headerColumn:nth-child(2),
  .trx-table .ReactVirtualized__Table__headerColumn:nth-child(3),
  .ReactVirtualized__Table__headerColumn:nth-child(2),
  .ReactVirtualized__Table__headerColumn:nth-child(3) {
    position: sticky;
    background-color: white;
  }

  .trx-table .ReactVirtualized__Table__row {
    &:not(:hover) .ReactVirtualized__Table__rowColumn {
      background-color: transparent;
    }

    &.selected .ReactVirtualized__Table__rowColumn {
      background-color: $tableHover;
    }
  }

  .trx-table:not(.stickyColumn2) .ReactVirtualized__Table__rowColumn:nth-child(3),
  .trx-table .ReactVirtualized__Table__rowColumn:nth-child(2) {
    position: sticky;
    min-height: 53px !important;
    height: auto !important;
    margin-bottom: 17px;
    background-color: white;
    z-index: 1;

    .arrowContainer {
      background-color: inherit;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -40px;
        top: -10px;
        height: 40px;
        width: 40px;
        background-color: white;
      }
    }
  }

  .trx-table.stickyColumn2 .ReactVirtualized__Table__rowColumn:nth-child(2) {
    margin-bottom: -5px;
  }

  .trx-table:not(.stickyColumn2)
    .ReactVirtualized__Table__row.is-stage
    .ReactVirtualized__Table__rowColumn:nth-child(3) {
    height: auto !important;
    background-color: inherit;
    &::before {
      content: '';
      position: absolute;
      width: 50px;
      height: 100%;
      left: -50px;
      top: 0;
      background-color: inherit;
      z-index: -1;
    }
  }
  .trx-table:not(.stickyColumn2)
    .ReactVirtualized__Table__row.is-stage
    .ReactVirtualized__Table__rowColumn:nth-child(3),
  .trx-table .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn:nth-child(2) {
    background-color: white;
  }

  .trx-table:not(.stickyColumn2)
    .ReactVirtualized__Table__row.is-stage
    .ReactVirtualized__Table__rowColumn:nth-child(3) {
    height: auto !important;
    background-color: inherit;
    &::before {
      content: '';
      position: absolute;
      width: 50px;
      height: 100%;
      left: -50px;
      top: 0;
      background-color: inherit;
      z-index: -1;
    }
  }
  .trx-table:not(.stickyColumn2)
    .ReactVirtualized__Table__row.is-stage
    .ReactVirtualized__Table__rowColumn:nth-child(3),
  .trx-table .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn:nth-child(2) {
    background-color: white;
  }

  .trx-table:not(.stickyColumn2) .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn:nth-child(3),
  .trx-table .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn:nth-child(2) {
    background-color: inherit;

    .arrowContainer {
      &::before {
        background-color: $tableHover;
      }
    }
  }

  .trx-table {
    &.stickyColumn2 .headerColumn {
      padding-left: 5px !important;
    }

    &:not(.stickyColumn2) .headerColumn {
      padding-left: 15px !important;
    }

    .headerColumn {
      position: absolute;
      left: 0;
      z-index: 2;
      &::before {
        content: '';
        position: absolute;
        left: -37px;
        width: 40px;
        top: 0;
        height: 35px;
        background-color: inherit;
        z-index: 100;
      }
    }
  }

  .trx-table:not(.stickyColumn2) .secondColumn {
    padding-left: 10px !important;
    padding-right: 20px !important;
    z-index: 2;
  }
  .trx-table.stickyColumn2 .secondColumn {
    padding-left: 30px !important;
    z-index: 2;
  }
}
.trx-table.stickyTable .ReactVirtualized__Table__headerColumn:nth-child(3),
.trx-table.stickyTable .ReactVirtualized__Table__rowColumn:nth-child(3) {
  margin-left: auto;
}
.trx-table.ml-auto-column-4 .ReactVirtualized__Table__headerColumn:nth-child(4),
.trx-table.ml-auto-column-4 .ReactVirtualized__Table__rowColumn:nth-child(4) {
  margin-left: auto;
}
